import React, { useEffect, useState, useRef, useContext } from 'react'

import { SecurityQuestion } from './SecurityQuestion'
import { useApiClient } from '../../../hooks/useApiClient'
import { Button, SectionalAlert } from '../../baseComponents'
import { myAccountContext, pathContext } from '../../../contexts/contexts'
import PageTitleHook from '../../../utils/PageTitleHook/PageTitleHook'

export const NUMBER_OF_SECURITY_QUESTIONS = 5

export interface ActiveQuestionProps {
  id: number
  question: string
  active: boolean
  old_index: number
}

export interface ErrorMsgsProps {
  question: string
  answer: string
}

export interface FirstErrorProps {
  index: number | undefined
  type: 'question' | 'answer' | undefined
}

const initialErrorMsgs = Array(NUMBER_OF_SECURITY_QUESTIONS).fill({
  question: '',
  answer: '',
})

const initialFirstError = { index: undefined, type: undefined }

export const SetSecurityQuestions = (props: { type: 'account-creation' | 'edit' }) => {
  const { type } = props

  PageTitleHook('USCIS Online Account | Security question')
  const { setUser, alert, setAlert, clearAlert } = useContext(myAccountContext)
  const { setUrl } = useContext(pathContext)

  const [activeQuestions, setActiveQuestions] = useState<Array<ActiveQuestionProps>>([])
  const [questionList, setQuestionList] = useState<Array<string>>([])
  const [securityAnswersData, setSecurityAnswersData] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [questionsAreLoaded, setQuestionsAreLoaded] = useState(false)
  const [answersAreLoaded, setAnswersAreLoaded] = useState(false)
  const [selectedQuestions, setSelectedQuestions] =
    useState<Array<string>>(Array(NUMBER_OF_SECURITY_QUESTIONS).fill(''))
  const [answers, setAnswers] = useState(
    Array(NUMBER_OF_SECURITY_QUESTIONS).fill('')
  )
  const [errorMsgs, setErrorMsgs] =
    useState<Array<ErrorMsgsProps>>(initialErrorMsgs)
  const [firstError, setFirstError] =
    useState<FirstErrorProps>(initialFirstError)
  const [disabledSubmit, setDisabledSubmit] = useState(false)

  const alertRef = useRef<HTMLDivElement>(null)

  const apiClient = useApiClient()

  const handleSelectQuestion = (idx: number, value: string) => {
    if (alert.message !== '') clearAlert()
    if (disabledSubmit) setDisabledSubmit(false)
    let selectedQuestionsCopy = [...selectedQuestions]
    selectedQuestionsCopy[idx] = value
    setSelectedQuestions(selectedQuestionsCopy)
    let answersCopy = [...answers]
    answersCopy[idx] = ''
    setAnswers(answersCopy)
  }

  const handleAnswerInput = (e: any, idx: number) => {
    if (alert.message !== '') clearAlert()
    if (disabledSubmit) setDisabledSubmit(false)
    setAnswers(
      answers.map((answ, i) => {
        if (idx === i) {
          return e.target.value
        }
        return answ
      })
    )
  }

  const updateErrMsg = (
    idx: number,
    key: 'question' | 'answer',
    value: string
  ) => {
    const errMsgsCopy = [...errorMsgs]
    errMsgsCopy[idx][key] = value
    setErrorMsgs(errMsgsCopy)
  }

  const validateFields = () => {
    const currentErrs = []

    for (let idx = 0; idx < NUMBER_OF_SECURITY_QUESTIONS; idx++) {
      const questionErrMsg =
        selectedQuestions[idx] === ''
          ? 'Please select a Security Question'
          : ''
      const answerErrMsg =
        answers[idx] === '' ? 'Security Answer cannot be blank' : ''
      currentErrs.push({ question: questionErrMsg, answer: answerErrMsg })
    }
    setErrorMsgs(currentErrs)

    const firstIdx = currentErrs.findIndex(
      (err) => err.question !== '' || err.answer !== ''
    )
    if (firstIdx >= 0) {
      setFirstError({
        index: firstIdx,
        type: currentErrs[firstIdx].question !== '' ? 'question' : 'answer',
      })
    }

    return firstIdx >= 0
  }

  useEffect(() => {
    apiClient
      .get('security_questions')
      .then((res) => {
        const questionObjs = res.data.questions
        const questions = questionObjs.map( (questionObj: ActiveQuestionProps) => questionObj.question)

        setActiveQuestions(questionObjs)
        setQuestionList(questions)
        setQuestionsAreLoaded(true)
      })
      .finally(() => {
        if (type === 'edit') {
          apiClient
            .get('/security_answers')
            .then((response) => {
              setSecurityAnswersData(response.data.security_answers)
              setAnswersAreLoaded(true)
              setIsLoading(false)
            })
            .catch((error) => {
              console.log(error.response)
            })
        } else {
          setAnswersAreLoaded(true)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.log(error.response)
      })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!questionsAreLoaded) return
    if (type !== 'edit') {
      setIsLoading(false)
      return
    }
    if (answersAreLoaded) {
      let answers = Object.values(securityAnswersData)
      let chosenQuestions: Array<string> = []
      let givenAnswers: Array<string> = []

      answers.forEach((answerObj: any) => {
        const questionId = answerObj['security_question_id']
        const answer = answerObj['text']
        const questionIdx = activeQuestions.findIndex(
          (obj: any) => obj.id === questionId
        )
        const question = activeQuestions[questionIdx].question
        chosenQuestions.push(question)
        givenAnswers.push(answer)
      })
      setSelectedQuestions(chosenQuestions)
      setAnswers(givenAnswers)
      setIsLoading(false)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsAreLoaded, answersAreLoaded])

  const createBody = () => {
    let body = {}
    for (let idx = 0; idx < NUMBER_OF_SECURITY_QUESTIONS; idx++) {
      const activeQuestionIndex = activeQuestions.findIndex((item => item.question === selectedQuestions[idx]))
      body = {
        ...body,
        [idx]: Object.assign({
          security_question_id: activeQuestions[activeQuestionIndex].id,
          text: answers[idx],
        }),
      }
    }
    return body
  }

  const handleReturn = (e: any) => {
    setUrl('/edit-account')
  }

  const handleFinish = (e: any) => {
    const errorsExist = validateFields()
    if (errorsExist) return

    const path = `/users/${type === 'edit' ? 'update_security_answers' : 'set_security_answers'
      }`
    const body = createBody()
    setDisabledSubmit(true)
    apiClient
      .post(path, { answers: body })
      .then((res) => {
        setUser(res.data)
        if (type === 'edit') {
          setAlert({
            type: 'success',
            message: 'You have successfully created your secret questions.',
            shouldPersist: true
          })
          setUrl('/edit-account')
        } else {
          if (res.data.lg_account_linked) {
            setAlert({
              type:
                res.data.lg_account_linked === 'success' ? 'success' : 'error',
              message: res.data.msg,
              shouldPersist: true
            })
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 409 || err.response.status === 400) {
          setAlert({ message: err.response.data.error, type: 'error' })
        }
      })
  }

  if (isLoading) return null

  return (
    <div
      className="card mx-auto"
      data-testid="set-security-questions-container"
    >
      <h1 className="py-1 text-2xl"> Security Questions</h1>
      {alert.message !== '' && (
        <div
          className="py-2"
          ref={alertRef}
          tabIndex={0}
          data-testid="sectional-alert"
        >
          <SectionalAlert type={alert.type}>{alert.message}</SectionalAlert>
        </div>
      )}
      <p className="text-md py-4">
        Set five security questions.  You must remember these answers when you are contacting the help desk.
      </p>
      {selectedQuestions.map((question, idx) => (
        <SecurityQuestion
          key={`security-question-${idx}`}
          allQuestions={questionList}
          selectedQuestions={selectedQuestions}
          questionNumber={idx}
          question={question}
          setQuestion={handleSelectQuestion}
          answer={answers[idx]}
          setAnswer={handleAnswerInput}
          errorMsgs={errorMsgs}
          setErrorMsgs={updateErrMsg}
          errorFocus={firstError}
        />
      ))}
      <div className="button-container mt-4">
        <Button
          id="set-security-questions-btn"
          data-testid="set-security-questions-btn"
          disabled={disabledSubmit}
          text="Finish"
          onClick={handleFinish}
        />
        {type === 'edit' && (
          <Button
            id="select-2fa-cancel-btn"
            data-testid="select-2fa-cancel-btn"
            text="Cancel"
            type="cancel"
            onClick={handleReturn}
          />
        )}
      </div>
    </div>
  )
}
