import React from 'react';
import './Spinner.css'

export const Spinner = () => {
  return (
    <div data-testid="spinner-div" className="flex justify-between">
        <div className="bg-blue-600 p-2 mx-1 w-2 h-2 rounded-full animate-pulse-cc"></div>
        <div className="bg-blue-600 p-2 mx-1 w-2 h-2 rounded-full animate-pulse-cc delay-1"></div>
        <div className="bg-blue-600 p-2 mx-1 w-2 h-2 rounded-full animate-pulse-cc delay-2"></div>
        <div className="bg-blue-600 p-2 mx-1 w-2 h-2 rounded-full animate-pulse-cc delay-3"></div>
    </div>
  );
};

