import React, { useContext } from 'react'
import { Button, SectionalAlert } from '../../../baseComponents'

import { pathContext } from '../../../../contexts/contexts'

const InvalidIalState = ({ error = '' }) => {
  const { setUrl } = useContext(pathContext)

  const handleDone = () => {
    if (error === 'Invalid user') {
      setUrl('https://qa-my.uscis.dhs.gov/') // SALLY -- this needs to be dynamic, or come from the backend?
    } else {
      setUrl('sign-in')
    }
  }

  return (
    <div className="card mx-auto" data-testid="create-account-container">
      <div className="mb-6">
      <div
          data-testid="sectional-alert-container"
          tabIndex={0}
        >
          <SectionalAlert type={'error'}>Invalid token</SectionalAlert>
        </div>
        <p className="font-normal py-8">Token received from USCIS is invalid</p>

        <div className="button-container mb-6">
          <Button id="ok-btn" text="OK" onMouseDown={handleDone} />
        </div>
      </div>
    </div>
  )
}
export default InvalidIalState
